import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Defining styled components for this component.
const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
    height: auto;
    border-top: 2px solid white;
    background-color: #353e4d;
    color: white;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;

    &>p {
      font-weight: bold;
    }

`;

const Footer = ({ title }) => (
  <FooterContainer>
    <p>
      Copyright ©2020
      {' '}
      {title}
    </p>
  </FooterContainer>
);

Footer.propTypes = {
  title: PropTypes.string
};

Footer.defaultProps = {
  title: `MYR Media`
};

export default Footer;
