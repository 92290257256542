import React, { useState, useEffect } from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { device } from './device';

// Defining styled components for this component.
const HeaderContainer = styled.header`

    // Flex settings.
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    // Other positioning.
    min-height: 5vh;
    width: 100%;
    z-index: 999;

    transform: translate(0, ${(props) => props.height});
    transition: 180ms linear;


    background-color: #353e4d;
    border-bottom: 3px solid #ffffff;
    position: fixed;
    top: 0;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);
`;

const HeroLogo = styled.img`
  object-fit: cover;
  height: 5vh;
  width: auto;
  z-index: 3;
  align-self: center;
  justify-self: center;
  padding: 1rem;
`;

const HeroNavLinkContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
`;

const HeroNav = styled(Link)`
  color: #ffffff;
  padding: 0.75rem 1rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.1rem;
  transition: 0.2s all;

  :hover {
    transform: scale(1.1,1.1);
  }

  @media ${device.tablet} {
    font-size: 0.9rem;
  }
`;

const ContactNav = styled(HeroNav)`
  border-radius: 10px;
  color: #353e4d;
  font-weight: bold;
  background-color: #ffffff;
`;

const Header = () => {
  const data = useStaticQuery(graphql`
  query {
    homePage: prismic {
      allHome_pages {
        edges {
          node {
            logo
          }
        }
      }
    }
  }
`);

  const doc = data.homePage.allHome_pages.edges.slice(0, 1).pop();

  const [headerHeight, setHeaderHeight] = useState(`0vh`);
  const [lastScroll, setLastScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (currentScroll > lastScroll) {
        setHeaderHeight(`-25vh`);
      } else if (window.pageYOffset <= `15vh`) {
        setHeaderHeight(`-25vh`);
      } else {
        setHeaderHeight(`0`);
      }
      setLastScroll(currentScroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });


  return (
    <HeaderContainer height={headerHeight}>
      <Link to="/">
        <HeroLogo src={doc.node.logo.url} alt={doc.node.logo.alt} />
      </Link>
      <HeroNavLinkContainer>
        <HeroNav aria-label="home-link" to="/">Home</HeroNav>
        <HeroNav aria-label="portfolio-link" to="/portfolio">Portfolio</HeroNav>
        <ContactNav aria-label="contact-link" to="/#contact">Contact</ContactNav>
      </HeroNavLinkContainer>
    </HeaderContainer>
  );
};

export default Header;
