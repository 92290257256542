import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { device } from './device';

// import components used in this component
import Header from './header';
import Footer from './footer';

// Places styles for site here using styled components.
const SiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const SiteBody = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <SiteContainer>
      <Header page={page} />
      <SiteBody>{children}</SiteBody>
      <Footer title={data.site.siteMetadata.title} />
    </SiteContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
